<template>
  <div>
    <ModalEditSpaceEventTypes
      v-if="showEditEventTypes"
      :showModal="showEditEventTypes"
      :onClickCancel="hideModal"
    />

    <ui-section-header :showEdit="true" :editFunction="setShowEditEventTypes">
      <template v-slot:title>{{
        $t('Components.Spaces.SpaceEventTypes.Main_Title')
      }}</template>
    </ui-section-header>

    <Message
      :message="
        $t('Components.Spaces.SpaceEventTypes.Message_WhatKindOfEvents')
      "
    />

    <div class="tags">
      <span
        v-for="(eventType, index) in space.EventTypes"
        :key="`et${index}`"
        class="tag is-info"
        >{{ eventType | getEventTypeName }}</span
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

const Message = () => import('@/components/UI/Message')
const ModalEditSpaceEventTypes = () =>
  import('@/components/Spaces/ModalEditSpaceEventTypes')

export default {
  components: {
    Message,
    ModalEditSpaceEventTypes,
  },

  props: {},

  data() {
    return {
      showEditEventTypes: false,
    }
  },

  computed: {
    ...mapState('spaceStore', ['space']),
    ...mapGetters('locationStore', ['spaceRights']),
  },

  created() {},

  methods: {
    setShowEditEventTypes() {
      this.showEditEventTypes = true
    },

    hideModal() {
      this.showEditEventTypes = false
    },
  },
}
</script>

<style></style>
